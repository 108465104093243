<template>
  <div class="flex class">
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <div class="lg:px-6 lg:mx-2">
        <NuxtPage />
      </div>
      <UNotifications />
    </NuxtLayout>
  </div>
</template>

<script setup>
const colorMode = useColorMode();
colorMode.preference = "dark";
</script>
