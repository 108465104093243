import { default as advertisevQLlPBA83XMeta } from "/workspace/pages/advertise.vue?macro=true";
import { default as _91id_93XCXbi15nUEMeta } from "/workspace/pages/coins/[id].vue?macro=true";
import { default as indexGvRgegYqDtMeta } from "/workspace/pages/coins/index.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91id_93z4WGN65YCKMeta } from "/workspace/pages/news/[id].vue?macro=true";
import { default as indexYTGC2mQSXYMeta } from "/workspace/pages/news/index.vue?macro=true";
import { default as privacy_45policyJuUuuPjb3dMeta } from "/workspace/pages/privacy-policy.vue?macro=true";
import { default as coincCisVOqkzwMeta } from "/workspace/pages/submit/coin.vue?macro=true";
import { default as newsH8TpBFMktVMeta } from "/workspace/pages/submit/news.vue?macro=true";
import { default as terms_45of_45userRdwVOUMqRMeta } from "/workspace/pages/terms-of-use.vue?macro=true";
import { default as watchlistOttRGKuidzMeta } from "/workspace/pages/watchlist.vue?macro=true";
export default [
  {
    name: "advertise",
    path: "/advertise",
    component: () => import("/workspace/pages/advertise.vue").then(m => m.default || m)
  },
  {
    name: "coins-id",
    path: "/coins/:id()",
    component: () => import("/workspace/pages/coins/[id].vue").then(m => m.default || m)
  },
  {
    name: "coins",
    path: "/coins",
    component: () => import("/workspace/pages/coins/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("/workspace/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/workspace/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "submit-coin",
    path: "/submit/coin",
    component: () => import("/workspace/pages/submit/coin.vue").then(m => m.default || m)
  },
  {
    name: "submit-news",
    path: "/submit/news",
    component: () => import("/workspace/pages/submit/news.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/workspace/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "watchlist",
    path: "/watchlist",
    component: () => import("/workspace/pages/watchlist.vue").then(m => m.default || m)
  }
]